import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'companyName']

  connect() {
    this.autocomplete = this.element.querySelector('[data-autocomplete]')
    this.suggestions = this.element.querySelector('[data-suggestions]')
    this.autocomplete.addEventListener('keyup', (event) => {
      const query = this.autocomplete.value
      if (query.length <= 2)  return
      const url = this.element.dataset.url + "?q=" + query
      console.log(url)
      fetch(url)
        .then(response => response.json())
        .then((result) => {
          console.log(result)
          if (result.length > 0) {
            console.log(result)
            this.suggestions.innerHTML = ""
            result.forEach((suggestion) => {
              this.suggestions.insertAdjacentHTML('beforeend', `
                <a data-action="autocomplete#choose" class="dropdown-item" href="#"
                  data-first-name="${suggestion.first_name}"
                  data-last-name="${suggestion.last_name}"
                  data-company-name="${suggestion.company_name}">
                  ${suggestion.first_name} ${suggestion.last_name} <br>
                  <small class="text-muted">
                    ${suggestion.company_name}
                  </small>
                </a>
              `)
            })
            $(this.suggestions).dropdown('show')
          } else {
            $(this.suggestions).dropdown('hide')
          }
        })
    })
  }

  hide(event) {
    setTimeout(() => {
      $(this.suggestions).dropdown('hide')
    }, 150)
  }

  choose(event) {
    event.preventDefault()
    event.stopPropagation()
    this.autocomplete.value = event.currentTarget.dataset
    // fill first_name
    this.firstNameTarget.value = event.currentTarget.dataset.firstName
    this.lastNameTarget.value = event.currentTarget.dataset.lastName
    this.companyNameTarget.value = event.currentTarget.dataset.companyName
    $(this.suggestions).dropdown('hide')
  }

}
