import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.autocomplete = document.querySelector('[data-autocomplete]')
    this.suggestions = document.querySelector('[data-suggestions]')
    const apiKey = "3mZ7SCAi2panaeQe07elwmc2SeXlLHh-ZMglM-7_pU0"

    this.autocomplete.addEventListener('keyup', (event) => {
      const query = this.autocomplete.value
      if (query.length <= 2)  return
      const url = `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${apiKey}&query=${query}&beginHighlight=%3Cb%3E&endHighlight=%3C/b%3E&language=fr`
      fetch(url)
        .then(response => response.json())
        .then((result) => {
          result = result.suggestions.filter(item => item.matchLevel == "city")
          if (result.length > 0) {
            console.log(result)
            this.suggestions.innerHTML = ""
            result.forEach((suggestion) => {
              this.suggestions.insertAdjacentHTML('beforeend', `<a data-action="autocomplete#choose" class="dropdown-item" href="#" data-city="${suggestion.address.city}, ${suggestion.address.country}">${suggestion.address.city}, ${suggestion.address.country}</a>`)
            })
            $(this.suggestions).dropdown('show')
          } else {
            $(this.suggestions).dropdown('hide')
          }
        })
    })
  }

  hide(event) {
    setTimeout(() => {
      $(this.suggestions).dropdown('hide')
    }, 150)
  }
  
  choose(event) {
    event.preventDefault()
    event.stopPropagation()
    this.autocomplete.value = event.currentTarget.dataset.city.replace('<b>', '').replace('</b>', '')
    $(this.suggestions).dropdown('hide')
  }

}
