import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "value", "radio" ]

  get step() {
    return parseInt(this.valueTarget.step) || 1
  }

  check() {
    if (!this.radioTarget.checked) {
      this.radioTarget.checked = "checked"
    }
  }

  add(event) {
    event.preventDefault()
    this.check()
    this.valueTarget.value = parseInt(this.valueTarget.value) + this.step
    this.valueTarget.dispatchEvent(new Event('change'));
  }

  remove(event) {
    event.preventDefault()
    this.check()
    var newValue = parseInt(this.valueTarget.value) - this.step
    if (this.valueTarget.min <= newValue) {
      this.valueTarget.value = parseInt(this.valueTarget.value) - this.step
      this.valueTarget.dispatchEvent(new Event('change'));
    }
  }
}
