import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "level"
  ]

  connect() {
    this.element.querySelectorAll('.level').forEach(function(level) {
      level.classList.add('d-none')
    })
    this.element.querySelector('.level-1').classList.remove('d-none')
    this.update()
  }

  update() {
    this.element.querySelectorAll('.level').forEach(function(level) {
      level.classList.add('d-none')
    })
    this.element.querySelector('.level-' + this.levelTarget.value).classList.remove('d-none')
  }
}
