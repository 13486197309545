import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "quantity",
    "subtotal",
    "tva",
    "total",
    "submit",
    "plan",
    "setup",
    "coaching",
    "firstMonth",
    "firstMonthValue"
  ]

  setup = {
    unit: false,
    pack: false,
    subscription: false
  }
  coaching = {
  }

  connect() {
    this.plan = this.data.get('plan')

    this.initializeUpselling()
    console.log("here")
    this.update()
  }

  initializeUpselling() {
    Object.keys(this.setup).forEach((plan) => {
      this.setup[plan] = document.querySelector(`[data-id="${plan}-setup-checkbox"]`).checked
    })

    this.coaching["subscription"] = document.querySelector(`[data-id="subscription-coaching-checkbox"]`).checked
  }

  toggleSetup(event) {
    console.log("click")
    this.setup[event.currentTarget.dataset.plan] = event.currentTarget.checked
    console.log(this.setup)
    document.getElementById(`radio-${event.currentTarget.dataset.plan}`).click()
  }

  toggleCoaching(event) {
    this.coaching[event.currentTarget.dataset.plan] = event.currentTarget.checked
    console.log(this.coaching)
    document.getElementById(`radio-${event.currentTarget.dataset.plan}`).click()
  }

  selectPlan(event) {
    this.plan = event.currentTarget.dataset.plan
    if (event.currentTarget.dataset.formaction){
      this.submitTarget.formAction = event.currentTarget.dataset.formaction
    }
    this.update()
  }

  get hasSetup() {
    return this.setupTarget.checked
  }

  get currentPlanTarget() {
    return this.planTargets.find(planTarget => planTarget.dataset.plan == this.plan)
  }

  get currentPlan() {
    return this.currentPlanTarget.dataset
  }

  get currentPlanName() {
    return this.currentPlan.plan
  }

  get unitPrice() {
    return parseInt(this.currentPlan.unitPrice)
  }

  get hasDiscount() {
    return (this.currentPlan.discountAt != "") && (parseInt(this.currentPlan.discountAt) <= this.quantity)
  }

  get unitPriceDiscount() {
    return parseInt(this.currentPlan.unitPriceDiscount)
  }

  get quantity() {
    return parseInt(this.currentPlanTarget.querySelector('[name="order[' + this.plan + '][credits]"]').value)
  }

  update() {
    let unitPrice
    if (this.hasDiscount) {
      unitPrice = this.unitPriceDiscount
    }
    else {
      unitPrice = this.unitPrice
    }
    let quantity = this.quantity
    let tvaRate = parseFloat(this.element.dataset.tva)
    let subtotal = unitPrice * quantity
    let firstMonth = 0

    if (this.coaching[this.currentPlanName]) {
      subtotal += 1200
    }


    if (this.setup[this.currentPlanName]) {
      if (this.plan == "subscription") {
        firstMonth = ((subtotal + 2000) * tvaRate) + subtotal + 2000
      } else {
        subtotal += 2000
      }
    }

    let tva = subtotal * tvaRate
    let total = subtotal + tva

    let perMonth = ""
    this.firstMonthTarget.classList.add('d-none')

    if (this.plan == "subscription") {
      if (this.setup[this.currentPlanName]) {
        this.firstMonthTarget.classList.remove('d-none')
        this.firstMonthValueTarget.innerHTML = price(firstMonth)
      }
      perMonth = "&nbsp;/&nbsp;mois"
    }

    this.subtotalTarget.innerHTML = price(subtotal) + perMonth
    this.tvaTarget.innerHTML = price(tva)
    this.totalTarget.innerHTML = price(total) + perMonth
  }

}
