import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["wrapper", "indicator", "item"]
  
  active = 0

  connect() {
    this.renderIndicator()
    let that = this

    let observer = new IntersectionObserver(function (entries, observer) {
      that.activeElement = entries.reduce(function (max, entry) {
        if (entry.intersectionRatio > max.intersectionRatio) {
          return entry
        } else {
          return max
        }
      }).target
      that.active = [...that.activeElement.parentElement.children].indexOf(that.activeElement)
      that.renderIndicator()
    }, {
      root: that.element, threshold: 1
    })
    this.itemTargets.forEach((item) => { observer.observe(item) })
  }

  renderIndicator() {
    this.indicatorTarget.innerHTML = '';
    let that = this
    this.itemTargets.forEach((item, index) => { 
      let button = document.createElement('div')
      button.classList.add('scroll-snap-indicator')
      if (index == this.active) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
      ((index) => { 
        button.onclick = () => {
          that.itemTargets[index].scrollIntoView(true)
        }
      })(index)

      this.indicatorTarget.appendChild(button)
    })
  }
}
