import { Controller } from 'stimulus'
import 'select2'

export default class extends Controller {
  connect() {
    console.log(this.data.get('tags') == false)
    let that = this;
    $('[data-select]').select2({
      width: '100%',
      language: {
        noResults: function() {
          return "Aucun résultat"
        }
      },
      theme: "bootstrap",
      tags: (that.data.get('tags') != "false"),
      data: this.data.get('tags'),
      ajax: {
        url: that.data.get('url'),
        dataType: 'json',
        data: function (params) {
          let query = {}
          query.q = params.term
          if (that.data.get('except')) {
            query.except = that.data.get('except')
          }
          return query;
        }
      }
    })
  }
}
