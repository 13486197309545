import '../../stylesheets/home.scss'
import Rails from 'rails-ujs'
import 'bootstrap'
import jQuery from 'jquery'

window.$ = jQuery
Rails.start()

import 'controllers'

import '../components/flyout'
import '../components/stacked_cards'
import '../components/hotjar'
