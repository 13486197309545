import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("hello")
  }

  capitalize(event) {
    let value = event.currentTarget.value
      event.currentTarget.value = value.charAt(0).toUpperCase() + value.slice(1)
  }
}




