window.onload = () => {
  let stackedCards = document.querySelectorAll('.stacked-cards')
  let mobileCards = document.querySelectorAll('.stacked-cards-mobile .stacked-card')
  let indicators = document.querySelectorAll('.stacked-cards-indicators div')
  stackedCards.forEach(el => {
    let card = el.querySelectorAll('.stacked-card:not(.hide)')

    let arrIndexes = [] // Index array
    let indexCopy
    (() => {
      // The loop that added values to the arrIndexes array for the first time
      let start = 0;
      while (card.length > start) {
        arrIndexes.push(start++);
      }
      indexCopy = arrIndexes.map((x) => x)
    })();

    let setIndex = (arr) => {
      for (let i = 0; i < card.length; i++) {
        card[i].dataset.slide = arr[i] // Set indexes
      }
    }
    el.addEventListener('click', () => {
      // arrIndexes.unshift(popped)
      arrIndexes.unshift(arrIndexes.pop())
      indexCopy.push(indexCopy.shift())

      indicators.forEach((indicator) => { indicator.classList.remove('active') })
      indicators[indexCopy[0]].classList.add('active')

      mobileCards.forEach((card) => { card.classList.add('d-none') })
      mobileCards[arrIndexes[0]].classList.remove('d-none')
      setIndex(arrIndexes)
    })
    setIndex(arrIndexes) // The first indexes addition
  });
};